<template>
  <div>
      <v-row class="mt-10 margen">

        <v-col
          align-self="center"
          v-for="(dataset,i) in datasets"
          :key="i"
          cols="12"
          sm="4"
        >
          <v-hover>
            <template v-slot:default="{ hover }"> <!-- v-slot:default="{ hover }"  (para vista de cada uno) -->
              <v-card
                elevation="24"
                class="mx-auto info-card"
                max-width="375"
                color="#f6f4f5"
              >
                <v-avatar
                  class="mt-2"
                  size="150"
                  rounded
                >
                  <v-img
                    src= "../assets/Logos/dataset.png"
                    transition="fade-transition"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <v-card-text>
                  <h2 class="text-h6 primary--text">
                  {{dataset.Nombre}}
                  </h2>
                  <h3>
                  {{dataset.Descrip}}
                  </h3>
                  

                </v-card-text>

                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                    color="#030303"
                  >
                    <v-btn
                      @click="verDataset(dataset.Nombre)"
                    >

                    See more info
                    
                    </v-btn>
                  </v-overlay>
                </v-fade-transition>

                <!--
                <v-chip-group column>
                  <v-chip
                    v-for="(tag,i) in dataset.Areas"
                    :key="i"
                    class="mx-auto contenido"
                    dark
                    color="#344898"
                  >
                    {{tag.name}}
                  </v-chip>
                </v-chip-group>
                -->
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>

    </div>
</template>

<script>

export default{
  name:'OpenData',
  data () {
    return {
      datasets:[
        {
          Nombre:'SCIAN-MorphoSpermGS',
          Descrip:'1,132 sperm head images',
          Areas:[
            {
              name:'Medicine'
            },
            {
              name:'Biomedical'
            },
          ]
        },
        {
          Nombre:'SCIAN-SpermSegGS',
          Descrip:'210 digital images of sperm',
          Areas:[
            {
              name:'Medicine'
            },
            {
              name:'Biomedical'
            },
          ]
        },
      ]
    }
  },
  methods:{
    verDataset(nombre){
      this.$router.push("/OpenDataDiinf/" + nombre.replace(/\s+/g, '_'));
    },
  }
  
}

</script>

<style scoped>
  .info-card{
    margin:0 auto 132px;
    width:75%;
    opacity: 0;
    animation: slide-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  @keyframes slide-top {
    0% {
      opacity: 0;
      transform: translateY(1000px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
</style>